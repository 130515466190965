<template>
    <div>
        <page-title :heading="$t('accounting.lang_accounting_cashierOverview') " :subheading="$t('accounting.lang_accounting_cashierOverview')" :icon=icon></page-title>
        <div class="app-main__inner">
        <cashier-overview></cashier-overview>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import CashierOverview from "../../components/accounting/CashierOverview";

    export default {
        components: {
            PageTitle,
            CashierOverview
        },

        data: () => ({
            icon: 'pe-7s-wallet icon-gradient bg-tempting-azure',
        })
    }
</script>