<template>
  <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="mb-3 card">
    <div>
      <v-container fluid>
        <v-layout>
          <v-flex md6 mr-2 ml-2>
            <BaseDateTimeInput v-model="startDateTime" outlined prepend-inner-icon="event" :label="$t('accounting.lang_dailyReportStart')"/>
          </v-flex>
          <v-flex md6 mr-2 ml-2>
            <BaseDateTimeInput v-model="endDateTime" outlined prepend-inner-icon="event" :label="$t('accounting.lang_dailyReportEnd')"/>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md6 mr-2 ml-2>
            <v-select clearable outlined item-text="text" item-value="value" v-model="dailyReportID"
                      :label="$t('accounting.lang_dailyReportID')"
                      v-if="dailyReportOptions && dailyReportOptions != null" :items="dailyReportOptions"></v-select>
          </v-flex>
          <v-flex md6 mr-2 ml-2>
            <v-select clearable outlined item-text="text" item-value="value" v-model="paymentOption"
                      :label="$t('accounting.lang_paymentOptions')" v-if="paymentOptions && paymentOptions != null"
                      :items="paymentOptions"></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex class="text-right">
            <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
            <v-btn color="success" @click="searchData">{{ $t('generic.lang_filter') }}</v-btn>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md12>
            <!-- Table -->
            <div style="margin-top: 20px;" v-if="items && items.length > 0">
              <b-table ref="cashOverviewTable" striped hover responsive
                       :items="items"
                       :fields="fields" :table-variant="this.$vuetify.theme.dark? 'dark' : ''">
                <template #cell(tax)="data">
                  {{data.item.tax | currency}}
                </template>
                <template #cell(net_sales)="data">
                  {{data.item.net_sales | currency}}
                </template>
                <template #cell(gross_sales)="data">
                  {{data.item.gross_sales | currency}}
                </template>
                <template v-slot:cell(selected)="data">
                  <!-- We use @click.stop here to prevent a 'row-clicked' event from also happening -->

                  <input v-if="data.item.selected !== null" value="1"
                         @click="recalculateSelected(data.item, data.index)" type="checkbox"
                         v-model="data.item.selected"/>

                </template>
              </b-table>
            </div>

          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<style scoped>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '../../config'
import DateTimePicker from "../common/datetimepicker";
import BaseDateTimeInput from "@/components/common/BaseDateTimeInput";

export default {
  components: {
    DateTimePicker,
    BaseDateTimeInput
  },
  data() {
    return {
      fields: [
        {key: 'selected', label: '', sortable: false, 'class': 'text-center'},
        {key: 'cashier_id', label: this.$t("generic.lang_cashierID"), sortable: false, 'class': 'text-center'},
        {key: 'tax', label: this.$t("erp.lang_tax"), sortable: false, 'class': 'text-center'},
        {key: 'net_sales', label: this.$t("generic.lang_netSales"), sortable: false, 'class': 'text-center'},
        {key: 'gross_sales', label: this.$t("erp.lang_grossSales"), sortable: false, 'class': 'text-center'},
      ],
      startDateTime: "",
      endDateTime: "",
      dailyReportOptions: null,
      paymentOptions: null,
      dailyReportID: null,
      paymentOption: null,
      items: [],
      loading: false,
      tab: 0,
      changedate: 0,
    }
  },
  methods: {
    recalculateSelected(row, index) {
      if (this.items != null && this.items.length > 2) {
        let tax = 0;
        let net_sales = 0;
        let gross_sales = 0;
        let selectedIndex = this.items.length - 2;
        let sign = "-";

        if (row.selected == false) {
          sign = "";
        }

        tax = Number(sign + row.tax.replace(" EUR", ""));
        net_sales = Number(sign + row.net_sales.replace(" EUR", ""));
        gross_sales = Number(sign + row.gross_sales.replace(" EUR", ""));

        tax = (Number(this.items[selectedIndex].tax) + tax);
        net_sales = (Number(this.items[selectedIndex].net_sales) + net_sales);
        gross_sales = (Number(this.items[selectedIndex].gross_sales) + gross_sales);

        this.items[selectedIndex].tax = tax;
        this.items[selectedIndex].net_sales = net_sales;
        this.items[selectedIndex].gross_sales = gross_sales;
      }
    },

    resetData: function () {
      this.startDateTime = "";
      this.endDateTime = "";
      this.dailyReportOptions = null;
      this.paymentOptions = null;
      this.dailyReportID = null;
      this.paymentOption = null;
      this.changedate = 0;

    },
    loadData: function () {
      let self = this;
      let items = [];
      let reportOptions = [];
      let paymentOptions = [];

      self.axios.post(ENDPOINTS.ACCOUNTING.CASHIER.GET, {
        dateStart: Date.parse(self.startDateTime) / 1000,
        dateEnd: Date.parse(self.endDateTime) / 1000,
        dailyReportID: self.dailyReportID,
        paymentOption: self.paymentOption,
        changeDate: self.changedate,
      })
          .then(function (result) {
            let data = result.data;
            let items = [];
            let paymentOptions = [];
            let reportOptions = [];

            if (data.dailyReportRay && data.dailyReportRay != null && data.dailyReportRay.length > 0) {
              for (let i = 0; i < data.dailyReportRay.length; i++) {
                let item = data.dailyReportRay[i];
                reportOptions.push({text: item[1], value: item[0]});
              }
            }
            self.dailyReportOptions = reportOptions;

            if (data.paymentTypes && data.paymentTypes != null && data.paymentTypes.length > 0) {
              for (let i = 0; i < data.paymentTypes.length; i++) {
                let item = data.paymentTypes[i];
                paymentOptions.push({text: item[1], value: item[0]});
              }
            }
            self.paymentOptions = paymentOptions;

            if (data.sumsPerCashier && data.sumsPerCashier != null && data.sumsPerCashier.length > 0) {
              for (let i = 0; i < data.sumsPerCashier.length; i++) {
                let item = data.sumsPerCashier[i];
                let row = {
                  selected: 0,
                  cashier_id: item.cashierID,
                  tax: item.tax,
                  gross_sales: item.total,
                  net_sales: item.net
                };
                items.push(row);
              }
            }

            items.push({
              selected: null,
              cashier_id: self.$t("generic.lang_AuswahlUmsatz"),
              net_sales: '0.00',
              gross_sales: '0.00',
              tax: '0.00'
            });

            if (data.totalSums && data.totalSums != null) {
              let item = data.totalSums;
              let row = {
                selected: null,
                cashier_id: self.$t("generic.lang_totalTurnover"),
                tax: item.tax,
                gross_sales: item.total,
                net_sales: item.net
              };
              items.push(row);
            }
            self.items = items;
          })
          .catch(function (error) {
          })
    },
    searchData: function () {
      this.changedate = 1;
      this.loadData();
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
  },
  mounted() {

    this.loadData();

  },
}
</script>